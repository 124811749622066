import _players2 from "./players";
import _ReactPlayer2 from "./ReactPlayer";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _players = _interopRequireDefault(_players2);
var _ReactPlayer = _ReactPlayer2;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

// Fall back to FilePlayer if nothing else can play the URL
var fallback = _players["default"][_players["default"].length - 1];
var _default = (0, _ReactPlayer.createReactPlayer)(_players["default"], fallback);
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;